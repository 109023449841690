import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import styles from './styles.module.scss'

const Strategic = () => {
  return <div className={styles.strategic}>
    <Container style={{
      maxWidth: 1170
    }}>
      <Row>
        <Col xs={12}>
          <h3>Strategic Partnerships</h3>
          <p>Urus Advisory has developed strategic partnerships, enabling us to provide advisory  services in fields in
            conjunction with top global experts. </p>
        </Col>
      </Row>
      <Row className={styles.row_wrapper}>
        <Col md={4}>
          <div className={styles.col}>
            <div className={styles.col_img}>
              <a target="_blank" href="https://globalsourcepartners.com/countries/advisory-services/home/">
                <img src={require('../../../../../public/images/RS-global.png')} />
              </a>
            </div>
            <p>Provides the highest quality macroeconomic and geopolitical research and analysis to emerging markets investors, corporations and multilaterals worldwide.</p>
          </div>
        </Col>
        <Col md={4}>
          <div className={styles.col}>
            <div className={styles.col_img}>
              <a target="_blank" href="https://irglobal.com/advisors/?firm=urus-advisory">
                <img width={122} src={require('../../../../../public/images/IR-global.png')} />
              </a>
            </div>
            <p>Multi-disciplinary professional services network that provides legal, accountancy, financial advice to companies and individuals around the world.</p>
          </div>
        </Col>
        <Col md={4}>
          <div className={styles.col}>
            <div className={styles.col_img}>
              <a target='_blank' href="https://clearpic.ai/">
                <img src={require('../../../../../public/images/ClearPic-Logo-White.png')} />
              </a>
            </div>
            <p>Compliance-screening platform focused on high-risk commodities markets in Central Asia and the Caspian Region.</p>
          </div>
        </Col>
      </Row>

    </Container>
  </div>
}

export default Strategic
