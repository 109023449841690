export const totalProjects = '5900+'

export const subregions = {
  "Northern Europe": {
    cords: null,
    projects: '>200',
    color: "#9e9e9e5c",
    marker: "#27ae60"
  },
  "Western Europe": {
    cords: null,
    projects: '>530',
    color: "#abb2b9",
    marker: "#95a5a6"
  },
  "Southern Europe": {
    cords: null,
    projects: '>210',
    color: "#bfc9ca",
    marker: "#000"
  },
  "Eastern Europe": {
    cords: null,
    projects: '>2500',
    color: "#85929e",
    marker: "#95a5a6"
  },

  "Western Asia": {
    cords: null,
    projects: '>725',
    color: "#b2babb",
    marker: '#e74c3c'
  },
  "Central Asia": {
    cords: null,
    projects: '>1300',
    color: "#eaecee",
    marker: "#2c3e50",
  },
  "Eastern Asia": {
    cords: null,
    projects: '>200',
    color: "#a9cce3",
    marker: "#f39c12"

  },
  "Southern Asia": {
    cords: null,
    projects: '>150',
    color: "#9ea7b2",
    marker: '#3498db'
  },
  "Southeastern Asia": {
    cords: null,
    projects: '>120',
    color: "#c7c7c7",
    marker: '#3498db'
  },
  "Northern Africa": {
    cords: null,
    projects: 0,
    color: "#c2e0f4",
    marker: "#fff"
  },
  "Eastern Africa": {
    cords: null,
    projects: 0,
    color: "#c2e0f4",
    marker: "#fff"
  },
  "Western Africa": {
    cords: null,
    projects: 0,
    color: "#c2e0f4",
    marker: "#fff"
  },
  "Central Africa": {
    cords: null,
    projects: 0,
    color: "#c2e0f4",
    marker: "#fff"
  },
  "Southern Africa": {
    cords: null,
    projects: 0,
    color: "#c2e0f4",
    marker: "#fff"
  },

  "Northern America": {
    cords: null,
    projects: 0,
    color: "#c2e0f4",
    marker: "#fff"
  },
  "Central America": {
    cords: null,
    projects: 0,
    color: "#c2e0f4",
    marker: "#fff"
  },
  "South America": {
    cords: null,
    projects: 0,
    color: "#c2e0f4",
    marker: "#fff"
  },

  "Antarctica": {
    cords: null,
    projects: 0,
    color: "#c2e0f4",
    marker: "#fff"
  },
  "Oceania": {
    cords: null,
    projects: 0,
    color: "#c2e0f4",
    marker: "#fff"
  },
}

